import React, { useState, useEffect } from "react";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddEscalaModal from "../../../../components/modelpopup/AddEscalaModal";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../../../components/Spinner";

import { startOfMonth, endOfMonth, format } from "date-fns";

import { Link, useParams, useNavigate } from "react-router-dom";

import DeleteModal from "../../../../components/modelpopup/deletePopup";

import Swal from "sweetalert2";

import EscalaFilter from "./EscalaFilter";
import AlterarNomeEmesModal from "../../../../components/modelpopup/AlterarNomeEmesModal";

const Escalas = () => {

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao, user, getMissao } = AuthUser();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [setSelectedOption] = useState(null);
  const [setSelecttwo] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateTwo, setSelectedDateTwo] = useState(null);

  const [dateTwo, setdateTwo] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [escalaSelecionada, setEscalaSelecionada] = useState(-1);
  const [descricaoSelecionada, setDescricao] = useState("");
  const [mesSelecionado, setMesSelecionado] = useState("");

  const [cargos, setCargos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [postos, setPostos] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [folhas, setFolhas] = useState([]);

  const [selectedFolhas, setSelectedFolhas] = useState([]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {


    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        //await getUtilizador();

        await getAssets();

      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  const handleFolhaSelect = (selectedFolha) => {
    setSelectedFolhas((prevSelectedFolhas) => {
      const isAlreadySelected = prevSelectedFolhas.some(
        (folha) => folha.id == selectedFolha.id
      );

      if (isAlreadySelected) {
        return prevSelectedFolhas.filter(
          (folha) => folha.id != selectedFolha.id
        );
      } else {
        return [...prevSelectedFolhas, { ...selectedFolha }];
      }
    });
  };

  const getFolhaClass = (folhaId) => {

    if (isSelected(folhaId)) {
      return "bg-primary text-white";
    }

    return "";

  };

  const isSelected = (folhaId) => {
    return selectedFolhas.some((folha) => folha.id == folhaId);
  };

  const getAssets = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/escalas/" + getMissao()?.id);

      setFolhas(res?.data?.escalas);
      setCargos(res?.data?.cargos);
      setDepartamentos(res?.data?.departamentos);
      setPostos(res?.data?.postos);
      setZonas(res?.data?.zonas);
      setBancos(res?.data?.bancos);

      setLoading(false);
      
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleFilterSubmit = (escalas) => {
    setFolhas(escalas);
  };

 
  const chamarModalEliminar = (idSelecionado) => {
    handleEliminar(idSelecionado);
  };

  const handleEliminar = async (idSelecionado) => {
    Swal.fire({
      title: "Remover escala?",
      text: "Tem a certeza que pretende remover esta escala e seus items?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {
      if (t.isConfirmed) {
        try {
          setLoading(true);

          try {
            const formData = new FormData();
            formData.append("id", idSelecionado?.id);

            console.log("Folha ID:");
            console.log(idSelecionado?.id);

            const response = await http.post(
              "/escala/eliminar",
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );

            getAssets();

            // Atualize a lista de acessos após ativar/desativar
          } catch (error) {
            console.error(error);

            toast.error(
              "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          } finally {
            setLoading(false);
          }

          setLoading(false);
        } catch (error) {
          setLoading(false);

          toast.error(
            "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  const handleSubmissionSuccess = (folha_id) => {
    // Refresh acessos state or trigger any necessary actions
    navigate("/escala-colaboradores/" + folha_id);
  };

  const submitFolhaSlarialPDFreport = async (folha_id) => {
    try {
      setLoading(true);

      await getReportFolha(folha_id);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportFolha = async (folha_id) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("folha_id", folha_id);

      const response = await http.post("/folha_salarial/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const getReportFolhaExcel = async (folha_id) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("folha_id", folha_id);

      const response = await http.post("/folha_salarial/excel", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(url_root + '' + response?.data?.url, "_blank");

    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const submitFolhaSlarialExcelreport = async (folha_id) => {
    try {
      setLoading(true);

      await getReportFolhaExcel(folha_id);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };


  const submitEfetividadePDFreport = async (escala) => {
    try {
      setLoading(true);

      await getReportEfetvidade(escala);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {

        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {


        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const submitEscalaPDFreport = async (escala) => {
    try {
      setLoading(true);

      await getReportescala(escala);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {

        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {

        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };


  const getReportEfetvidade = async (escala) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("escala_id", escala?.id);
      formData.append("missao_id", escala?.missao_id);

      const response = await http.post("/efetividade/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const getReportescala = async (escala) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("escala_id", escala?.id);
      formData.append("missao_id", escala?.missao_id);

      const response = await http.post("/escala/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const getTotalValor = () => {
    return folhas.reduce((total, folha) => total + parseFloat(folha.total), 0);
  };

  const getTotalNaoPaga = () => {
    return folhas.reduce((total, folha) => {
      return folha.paga != 1 ? total + parseFloat(folha.total) : total;
    }, 0);
  };

  const getTotalPaga = () => {
    return folhas.reduce((total, folha) => {
      return folha.paga == 1 ? total + parseFloat(folha.total) : total;
    }, 0);
  };

  const getTotalSelecionadas = () => {
    return selectedFolhas.reduce(
      (total, folha) => total + parseFloat(folha.total),
      0
    );
  };


  //Alterar descrição e Mês

  const onUpdateNameSubmissionSuccess = (nome,mes,ano) => {

    console.log("Descrição e mês :");
    console.log(nome);
    console.log(mes);

    setDescricao(nome);
    setMesSelecionado(mes);

    uploadDescricaoEMes(nome,mes,ano);

  };


  const chamarModalAlterarDescricao = (descricao,mes,escala_id) => {

    setDescricao(descricao);
    setMesSelecionado(mes);
    setEscalaSelecionada(escala_id);

    const editButton = document.getElementById("triggerModalAlterarDescricao");

    if (editButton) {
      editButton.click();
    }
  };

  const uploadDescricaoEMes = async (nome,mes,ano) => {

    if (verificarPermissao("OPERACOES", "adicionar")) {
      try {
        const formData = new FormData();

        formData.append("descricao", nome);
        formData.append("missao_id", getMissao()?.id);
        formData.append("mes", mes);
        formData.append("ano", ano);
        formData.append("id", escalaSelecionada);

        const response = await http.post(
          "/escala/alterar/descricao-mes",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        navigate("/escala-colaboradores/" + response?.data?.escala_id);

        setLoading(false);

      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t("Escalas")}
            title="Dashboard"
            subtitle={t("escalas")}
            modal="#add_group_escala"
            name={t("Criar escala")}
          />

          <EscalaFilter
            onFilterSubmit={handleFilterSubmit}
            departamentos={departamentos}
            cargos={cargos}
            postos={postos}
            zonas={zonas}
            folhasSelecionadas={selectedFolhas}
          />

       
          <div className="row">
            <div className="col-sm-12">
              <div className="table-responsive">
                <table className="table table-striped table-responsive">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>X</th>
                      <th>Escala</th>
                      <th>Zona</th>
                      <th>De</th>
                      <th>A</th>
                      <th>Ano</th>
                      <th>Estado</th>
                      <th>Exportar</th>
                      <th>
                        <i className="fa fa-cogs"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {folhas?.slice(0, 356).map((item, index) => (
                      <tr key={index} title={item?.designacao}>
                        <td>{index + 1}</td>
                        <td>
                          <span className="avatar">
                            <img
                              src={url_root + "/storage/" + item?.logo}
                              alt={'Sem Logo'}
                              title={item?.nota}
                            />
                          </span>
                        </td>
                        <td
                          onClick={() => handleFolhaSelect(item)}
                          className={`${getFolhaClass(item.id)}`}
                        >
                          <Link
                            to={"/escala-colaboradores/" + item?.id}
                            className="btn btn-white btn-sm btn-rounded"
                          >
                            {item?.designacao}
                          </Link>
                        </td>
                        <td>{item?.zona?.nome}</td>
                        <td>{format(
                          new Date(item?.data1),
                          "dd/MM/yyyy"
                        )}</td>
                        <td>{format(
                          new Date(item?.data2),
                          "dd/MM/yyyy"
                        )}</td> {/* Nome do mês */}
                        <td>{item?.ano}</td>

                        <td>
                          {item?.estado != 1 ? (
                            <>
                              <Link className="btn btn-white btn-sm btn-rounded">
                                <i className="far fa-dot-circle text-danger" />{" "}
                                {t("Aberta")}
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link
                                className="btn btn-white btn-sm btn-rounded"
                                title={format(
                                  new Date(item?.data_fecho),
                                  "dd/MM/yyyy"
                                )}
                              >
                                <i className="far fa-dot-circle text-success" />{" "}
                                {t("Fechada")}
                              </Link>
                            </>
                          )}
                        </td>
                        <td>
                          <span
                            className="btn btn-sm btn-danger text-white"
                            onClick={() => submitEscalaPDFreport(item)}
                          >
                            <i className="fa fa-file-pdf"></i> {t("Escala")}
                          </span>
                          {" "}
                          <span
                            className="btn btn-sm btn-danger text-white"
                            onClick={() => submitEfetividadePDFreport(item)}
                          >
                            <i className="fa fa-file-pdf"></i> {t("Efetividade")}
                          </span>

                        </td>
                        <td>
                          <div className="dropdown dropdown-action text-end">
                            <Link
                              to="#"
                              className="action-icon dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="material-icons">more_vert</i>
                            </Link>

                            <div className="dropdown-menu dropdown-menu-right">
                              <span
                                className="dropdown-item"
                                onClick={() =>
                                  chamarModalAlterarDescricao(item?.designacao,item?.mes,item?.id)
                                }
                              >
                                <i className="fa fa-edit m-r-5 text-danger" />{" "}
                                {t("Alterar descrição e mês")}
                              </span> 

                              <span
                                className="dropdown-item"
                                onClick={() =>
                                  submitFolhaSlarialPDFreport(item?.id)
                                }
                              >
                                <i className="fa fa-file-pdf m-r-5 text-danger" />{" "}
                                {t("Exportar PDF")}
                              </span>

                              {item?.paga != 1 ? (
                                <>
                                  <span
                                    className="dropdown-item"
                                    onClick={() => chamarModalEliminar(item)}
                                  >
                                    <i className="fa fa-trash m-r-5" />{" "}
                                    {t("Delete")}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {loading ? (
            <>
              <Spinner></Spinner>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <AddEscalaModal
        bancos={bancos}
        cargos={cargos}
        departamentos={departamentos}
        postos={postos}
        onSubmissionSuccess={handleSubmissionSuccess}
      />

<AlterarNomeEmesModal
            onUpdateNameSubmissionSuccess={onUpdateNameSubmissionSuccess}
            nomeEdit={descricaoSelecionada}
            mesEdit={mesSelecionado}
            tipo={1}
          />


<span
        data-bs-toggle="modal"
        data-bs-target="#alterar_descricao_modal"
        id="triggerModalAlterarDescricao"
      ></span>



    </>
  );
};

export default Escalas;
